import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import useWindowDimensions from "../../../components/useWindowDimensions"

import { gsap } from "gsap"
// import { GSDevTools } from "gsap/dist/GSDevTools"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { CustomEase } from "gsap/CustomEase"
import { CustomWiggle } from "gsap/CustomWiggle"

import {
  StepWrapper,
  Step,
  Panel,
  PanelContent,
  Label,
} from "../../../styles/how-it-works"
import { useTranslateValue } from "../../../data/TranslateContext"

const PlaceCup = styled.div`
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);

  width: 160px;

  user-select: none;
`

export default function Place() {
  const images = useStaticQuery(graphql`
    query placeStepQuery {
      placeCup: file(relativePath: { eq: "how-it-works/place-cup.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { width } = useWindowDimensions()

  const [triggerRef, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    const speed = width >= 768 ? 1000 : 0
    const timer = setTimeout(() => {
      if (inView) {
        clickFeature()
      }
    }, speed)

    return () => clearTimeout(timer)
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  let cupRef = useRef(null)
  const [featureTimeline] = useState(gsap.timeline({ paused: true }))

  const actionLinesPaths = [
    "M0.22854348,1.47493742 C0.628536738,1.91633366 1.03711055,2.3521525 1.45414485,2.78236594 C1.79334214,3.13228256 2.35197933,3.14097229 2.70189595,2.801775 C3.05181257,2.46257772 3.0605023,1.90394052 2.72130501,1.5540239 C2.31799097,1.13796431 1.92294212,0.71657232 1.53626981,0.289875864 C1.20902409,-0.0712427894 0.650994517,-0.0987022329 0.289875864,0.22854348 C-0.0712427894,0.555789193 -0.0987022329,1.11381877 0.22854348,1.47493742 Z",
    "M5.3845115,6.46118828 C5.83758239,6.84673953 6.2981512,7.22676967 6.76608382,7.60124853 C7.14657668,7.90575078 7.70187494,7.84414822 8.00637719,7.46365536 C8.31087944,7.08316249 8.24927687,6.52786424 7.86878401,6.22336199 C7.41464796,5.85992429 6.96775757,5.49118066 6.52824074,5.11716355 C6.15709856,4.80133144 5.60019586,4.84616912 5.28436376,5.2173113 C4.96853165,5.58845348 5.01336932,6.14535617 5.3845115,6.46118828 Z",
    "M11.1212228,10.7864225 C11.6155359,11.1168914 12.1161894,11.4419294 12.6230326,11.7615043 C13.0352668,12.0214263 13.580157,11.8979528 13.840079,11.4857186 C14.1000009,11.0734845 13.9765275,10.5285943 13.5642933,10.2686723 C13.0707086,9.95745718 12.583249,9.64098494 12.1020611,9.31929088 C11.6969239,9.04843986 11.1489271,9.15730034 10.8780761,9.56243755 C10.6072251,9.96757477 10.7160855,10.5155715 11.1212228,10.7864225 Z",
    "M17.2556624,14.4405702 C17.7803909,14.718541 18.3102784,14.9911697 18.8451543,15.2584222 C19.2811014,15.4762446 19.8110862,15.2994196 20.0289086,14.8634725 C20.246731,14.4275254 20.069906,13.8975406 19.6339589,13.6797182 C19.1115583,13.418699 18.5941137,13.1524722 18.081793,12.8810744 C17.6511502,12.6529447 17.1171095,12.8171141 16.8889798,13.2477569 C16.6608501,13.6783998 16.8250195,14.2124405 17.2556624,14.4405702 Z",
    "M23.7363673,17.4955589 C24.2841577,17.7242205 24.8359213,17.9475733 25.3914615,18.1655822 C25.8451166,18.3436086 26.3571958,18.1201674 26.5352223,17.6665122 C26.7132487,17.2128571 26.4898075,16.7007779 26.0361524,16.5227515 C25.4923386,16.3093443 24.9522859,16.0907322 24.4161894,15.8669518 C23.966462,15.6792241 23.4497026,15.8916169 23.2619749,16.3413443 C23.0742472,16.7910717 23.2866399,17.3078312 23.7363673,17.4955589 Z",
    "M30.3959748,19.9527058 C30.9599207,20.1349724 31.5266164,20.3119085 32.0958318,20.4834801 C32.5624326,20.6241218 33.0547,20.3598797 33.1953417,19.8932788 C33.3359834,19.426678 33.0717413,18.9344106 32.6051404,18.7937689 C32.0470769,18.6255588 31.4915251,18.4521021 30.9387135,18.273434 C30.4749954,18.1235608 29.9775814,18.3779824 29.8277083,18.8417005 C29.6778351,19.3054186 29.9322567,19.8028326 30.3959748,19.9527058 Z",
    "M37.2704263,21.8793905 C37.8478556,22.0173633 38.4267139,22.1498612 39.0067249,22.2768537 C39.4827838,22.3810861 39.9532028,22.0796612 40.0574352,21.6036023 C40.1616676,21.1275434 39.8602426,20.6571244 39.3841838,20.5528921 C38.8150746,20.4282864 38.2471109,20.2982822 37.6805674,20.1629106 C37.2065745,20.0496532 36.7305142,20.3420872 36.6172569,20.81608 C36.5039995,21.2900728 36.7964334,21.7661332 37.2704263,21.8793905 Z",
    "M44.2785335,23.2747653 C44.8661477,23.3687586 45.4536459,23.4570135 46.040691,23.5395076 C46.5232854,23.6073238 46.9694815,23.2710797 47.0372977,22.7884852 C47.1051139,22.3058908 46.7688698,21.8596948 46.2862753,21.7918785 C45.7102977,21.7109397 45.1338551,21.6243456 44.5572831,21.5321185 C44.0760645,21.4551439 43.6235595,21.7828485 43.5465849,22.2640671 C43.4696104,22.7452857 43.7973149,23.1977907 44.2785335,23.2747653 Z",
    "M51.3782858,24.1319349 C51.9733472,24.1802676 52.5663392,24.2224365 53.1568441,24.2584356 C53.6432771,24.2880902 54.0616487,23.9177982 54.0913033,23.4313652 C54.1209579,22.9449322 53.7506658,22.5265606 53.2642329,22.496906 C52.6855791,22.4616293 52.1044153,22.4203015 51.5211577,22.3729276 C51.0354212,22.3334746 50.6096711,22.6952589 50.5702181,23.1809953 C50.5307651,23.6667318 50.8925494,24.0924819 51.3782858,24.1319349 Z",
    "M58.5010457,24.4153708 C59.1011782,24.4133998 59.6965482,24.4045971 60.2866298,24.3889828 C60.7737954,24.3760918 61.1582708,23.9707159 61.1453798,23.4835503 C61.1324888,22.9963848 60.7271129,22.6119093 60.2399474,22.6248003 C59.6635447,22.6400527 59.0818084,22.6486538 58.4952638,22.6505755 C58.0079304,22.6521721 57.6141629,23.0485283 57.6157547,23.5358617 C57.6173562,24.0231951 58.0137123,24.4169626 58.5010457,24.4153708 Z",
    "M65.6160267,24.0470811 C66.21805,23.9841682 66.8111481,23.913312 67.3946447,23.8345958 C67.8776058,23.7694423 68.2163058,23.3251076 68.1511523,22.8421465 C68.0859988,22.3591853 67.6416641,22.0204853 67.158703,22.0856388 C66.5928002,22.1619815 66.0172061,22.2307466 65.4325996,22.2918394 C64.947903,22.3424913 64.5960403,22.7764771 64.6466923,23.2611738 C64.6973443,23.7458704 65.1313301,24.0977331 65.6160267,24.0470811 Z",
    "M72.6865781,22.8383287 C73.2841906,22.6895189 73.8652261,22.5306669 74.4287763,22.3619697 C74.8956434,22.2222144 75.1608199,21.7304497 75.0210646,21.2635826 C74.8813093,20.7967155 74.3895446,20.531539 73.9226775,20.6712944 C73.3858442,20.8319939 72.8313615,20.9835865 72.2601515,21.125822 C71.7872558,21.2435764 71.499357,21.722393 71.6171115,22.1952887 C71.7348659,22.6681843 72.2136825,22.9560832 72.6865781,22.8383287 Z",
  ]
  let actionLinesRefs = useRef([])

  const setupFeatureTimeline = () => {
    featureTimeline.set(cupRef.current, {
      // rotate: 6,
      translateX: 0,
      transformOrigin: "50% 50%",
    })
    featureTimeline.to(
      cupRef.current,
      {
        duration: 0.5,
        rotate: 8,
        ease: "CupWiggle",
        force3D: false,
      },
      "-=1"
    )

    featureTimeline.fromTo(
      actionLinesRefs.current,
      {
        scale: 0,
      },
      {
        duration: 0.1,
        stagger: 0.05,
        scale: 1,
      },
      "-=0.5"
    )

    // featureTimeline.play()
  }

  const clickFeature = e => {
    featureTimeline.restart()
    featureTimeline.play()
  }

  useEffect(() => {
    // gsap.registerPlugin(GSDevTools, MotionPathPlugin, CustomWiggle, CustomEase)
    gsap.registerPlugin(MotionPathPlugin, CustomWiggle, CustomEase)

    CustomWiggle.create("CupWiggle", {
      wiggles: 4,
      type: "easeInOut",
    })

    setupFeatureTimeline()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [{ language }] = useTranslateValue()

  return (
    <Step>
      <Panel ref={triggerRef}>
        <PanelContent>
          {language === "en" && <Label>Place in the Holder</Label>}
          {language === "fr" && <Label>Placer dans le Support</Label>}

          <StepWrapper>
            <PlaceCup
              onClick={clickFeature}
              ref={el => {
                cupRef.current = el
              }}
            >
              <Img fluid={images.placeCup.childImageSharp.fluid} />
            </PlaceCup>

            <svg
              width="270px"
              height="248px"
              viewBox="0 0 270 248"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              onClick={clickFeature}
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Blender" transform="translate(143.000000, 36.000000)">
                  <path
                    d="M8.3044,128.14352 L33.6172,132.41952 L33.6172,179.91712 C33.6172,179.91712 33.8844,184.93232 29.0188,185.65472"
                    id="Stroke-3"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M67.78096,127.95056 L91.65056,131.43296 C94.88736,131.67616 97.39776,134.11536 97.45776,137.08576 C97.71216,149.52736 97.15936,178.45776 97.15936,184.66896 C97.15936,192.49536 93.50256,192.97216 93.50256,192.97216 C93.50256,192.97216 87.29936,194.28656 85.21056,194.34656 C83.12096,194.40576 80.24816,193.21136 78.42016,191.00096 C76.59216,188.79136 69.11936,187.37536 69.11936,187.37536 C69.11936,187.37536 54.85056,186.24736 42.88496,185.88656 C30.91936,185.52576 14.15616,185.93296 14.15616,185.93296 C14.15616,185.93296 11.26336,187.01536 9.42256,188.81936 C7.58176,190.62416 0.87536,197.00016 0.87536,197.00016 C0.87536,197.00016 0.34976,197.84176 0.74416,200.48896 L1.66416,206.62416 C1.66416,206.62416 2.58496,208.06736 4.82016,208.06736 C7.05536,208.06736 56.49696,210.95536 64.51776,210.71456 C72.53776,210.47376 81.34816,209.27056 87.00176,208.30816 C91.68336,207.51136 113.39856,204.07216 120.73616,202.91136 C122.21936,202.67696 123.41296,201.67296 123.79776,200.34176 L125.60336,194.08736 C125.70176,193.74576 125.74896,193.39376 125.74416,193.04016 L124.45936,106.39056 L125.93616,12.50096 C126.12176,8.95216 107.65376,0.62336 67.39536,0.62336 C46.30096,0.62336 30.39296,2.28256 19.72496,3.83136"
                    id="Stroke-5"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M72.75128,122.41896 L91.85208,125.67496 C95.08968,125.91816 97.59928,128.35656 97.66008,131.32776 L97.77848,140.66856"
                    id="Stroke-7"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <line
                    x1="96.03328"
                    y1="133.18808"
                    x2="33.61648"
                    y2="133.05688"
                    id="Stroke-9"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="73.56528"
                    y1="133.49704"
                    x2="73.56528"
                    y2="186.16504"
                    id="Stroke-11"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="70.3044"
                    y1="133.3904"
                    x2="70.3044"
                    y2="183.1816"
                    id="Stroke-13"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="62.15048"
                    y1="133.28376"
                    x2="62.15048"
                    y2="153.34456"
                    id="Stroke-15"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="58.77312"
                    y1="133.17752"
                    x2="58.77312"
                    y2="151.42632"
                    id="Stroke-17"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <path
                    d="M44.54968,157.2748 C46.03928,155.5092 51.10648,154.078 55.86648,154.078 C59.75288,154.078 62.23288,155.0316 62.20168,156.3428 L62.20168,159.0692 C62.20168,159.0692 61.62488,170.1132 61.55928,171.1948 C61.49288,172.278 61.49288,172.9996 60.24408,172.9996 C60.24408,172.9996 49.06648,173.4212 47.68648,173.3604 C46.30568,173.3004 44.66248,173.1204 44.59688,171.8572 L44.13608,160.8972 L44.13608,158.17 C44.15288,157.8868 44.28728,157.586 44.54968,157.2748 Z"
                    id="Stroke-19"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M44.54968,157.2748 C44.28728,157.586 44.15288,157.8868 44.13608,158.17 C44.05848,159.4996 46.54808,160.4716 50.46968,160.4716 C55.23048,160.4716 60.29768,159.0404 61.78808,157.2748 C62.06168,156.9508 62.19528,156.638 62.20168,156.3428 C62.23288,155.0316 59.75288,154.078 55.86648,154.078 C51.10648,154.078 46.03928,155.5092 44.54968,157.2748 Z"
                    id="Stroke-21"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M8.74816,13.04864 L60.32656,13.04864 C60.82576,13.04864 61.23056,13.45344 61.23056,13.95264 L61.23056,117.36464 C61.23056,117.86384 60.82576,118.26784 60.32656,118.26784 L8.74816,118.26784 C8.24896,118.26784 7.84416,117.86384 7.84416,117.36464 L7.84416,13.95264 C7.84416,13.45344 8.24896,13.04864 8.74816,13.04864 Z"
                    id="Stroke-23"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M58.17544,174.1748 C58.17544,174.1748 57.96024,183.2052 54.97304,186.0092"
                    id="Stroke-25"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <line
                    x1="61.00752"
                    y1="173.31072"
                    x2="62.05712"
                    y2="186.16992"
                    id="Stroke-27"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <path
                    d="M47.26608,174.46232 C47.26608,174.46232 47.10688,184.62552 43.66048,185.80792"
                    id="Stroke-29"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M77.71064,191.4264 C77.71064,191.4264 72.27544,197.0408 70.52264,198.4832 C68.76904,199.9272 61.40504,201.0496 57.72424,201.0496 C54.04184,201.0496 38.78904,201.3712 29.14584,200.4088 C19.50344,199.4456 5.82824,198.8048 5.82824,198.8048 C5.82824,198.8048 1.18264,198.404 0.87544,197"
                    id="Stroke-31"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <polygon
                    id="Stroke-33"
                    stroke="#004E7D"
                    strokeWidth="1.1984"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="57.64656 188.9748 47.00096 198.2628 13.40096 196.4428 23.44256 188.2468"
                  ></polygon>
                  <path
                    d="M65.602,128.16856 C69.158,128.16856 72.0412,125.53096 72.0412,122.27736 L72.0468,122.27736 L72.0468,12.44216 C72.0468,11.20776 71.6316,10.06296 70.9228,9.11656 C70.5068,8.56136 69.882,8.17416 69.1876,7.95176 C65.542,6.77976 52.0348,2.84056 35.2556,2.84056 C15.5476,2.84056 6.3404,7.01336 6.3404,7.01336 C4.0292,7.90776 2.4076,10.00216 2.4076,12.44216 L2.4076,122.27736 C2.4076,125.53096 5.2908,128.16856 8.8476,128.16856 L65.6068,128.16856 L65.602,128.16856 Z"
                    id="Stroke-35"
                    stroke="#004E7D"
                    strokeWidth="1.2472"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M5.52936,8.07992 C5.52936,8.07992 15.30456,4.41112 33.49816,4.41112 C48.98696,4.41112 60.51736,7.98312 64.73896,9.34232 C65.37976,9.54792 65.95656,9.90472 66.34056,10.41752 C66.99496,11.29112 67.37816,12.34792 67.37816,13.48712 L67.49336,122.27752 C67.49336,125.53112 64.61016,128.16872 61.05336,128.16872"
                    id="Stroke-37"
                    stroke="#004E7D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M63.02312,161.33656 C63.01512,161.65736 62.88232,162.31816 62.58392,162.67096 C60.96152,164.59496 55.42952,165.83496 50.24472,165.83496 C45.97352,165.83496 43.26072,164.77656 43.34632,163.32696 L43.34632,159.31416 C43.26072,160.76216 45.97352,161.82056 50.24472,161.82056 C55.42952,161.82056 60.94792,160.26136 62.57192,158.33816 C62.86952,157.98456 62.99832,157.73256 63.00632,157.41176 L63.02312,161.33656 Z"
                    id="Fill-39"
                    fill="#004E7D"
                  ></path>
                  <path
                    d="M50.24496,158.91232 C51.47696,158.91232 52.70976,158.82592 53.93136,158.66432 C55.76496,158.42352 57.59696,158.02592 59.33136,157.37392 C60.02416,157.11312 60.70656,156.81072 61.33936,156.42592 C61.58576,156.27632 61.90576,156.10912 62.09696,155.88992 C62.30656,155.64992 62.49776,155.24992 62.76656,155.76912 C62.90816,156.04272 63.00816,156.18432 63.01456,156.49632 C63.02016,156.80032 63.01696,157.10512 63.01856,157.40992 L63.02336,158.42752 C63.01536,158.74832 62.86896,159.08992 62.57136,159.44272 C60.94816,161.36592 55.42896,162.92592 50.24496,162.92592 C45.97296,162.92592 43.26096,161.86672 43.34656,160.41872 C43.37856,159.86272 43.24336,159.30912 43.30176,158.74992 C43.34896,158.29552 43.68816,157.60272 44.24896,157.76112 C44.48576,157.82752 44.71136,158.02032 44.94656,158.11712 C45.20816,158.22592 45.47776,158.31552 45.75056,158.39312 C47.20176,158.80752 48.74256,158.91232 50.24496,158.91232"
                    id="Fill-41"
                    fill="#004E7D"
                  ></path>
                  <path
                    d="M61.80128,161.62328 C60.97728,164.18568 47.98128,166.14488 44.66288,163.36888 L44.66288,158.89368 C47.84608,162.01288 61.38448,159.40648 61.99008,157.41128 L61.80128,161.62328 Z"
                    id="Fill-43"
                    fill="#DE2731"
                  ></path>
                </g>
                <g
                  id="actionLines"
                  transform="translate(115.200073, 173.675593)"
                  fill="#004E7D"
                  fillRule="nonzero"
                >
                  {actionLinesPaths.map((path, pathIndex) => (
                    <path
                      className="action-line-part"
                      key={`place-line-${pathIndex}`}
                      d={path}
                      ref={el => {
                        actionLinesRefs.current[pathIndex] = el
                      }}
                    ></path>
                  ))}
                </g>
              </g>
            </svg>
          </StepWrapper>
        </PanelContent>
      </Panel>
    </Step>
  )
}
